@import "~bootstrap/scss/bootstrap";

@import "~animate.css/animate.min.css";

body {
    width: 100%;
    height: 100%;
    background-color: white;
}




/* customize some Bootstrap variables */

$primary: darken(#428bca, 20%);

// https://stackoverflow.com/questions/21458162/creating-watermark-using-html-and-css

// the ~ allows you to reference things in node_modules
//las incluyo todas a efectos de desarrollo, habría que incluir solo las que necesitemos




